import Posts from "./components/Posts.jsx";
import Bar from "./components/Bar.jsx"

const App = () => {
    return (
      <div>
        <Posts />
      </div>
    )
}
 
export default App;